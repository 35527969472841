import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

const Options = ({ onChange, options, selectedValue, ariaLabel }) => {
  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current
        .getElementsByTagName("li")[0]
        ?.focus({ preventScroll: true });
    }
  }, [options]);

  const handleOptionClick = e => {
    const eventValue = e.currentTarget.getAttribute("data-value");
    onChange(eventValue);
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      const eventValue = e.currentTarget.getAttribute("data-value");
      onChange(eventValue);
    }
  };

  return (
    <ul
      className="overflow-y-auto rounded-lg"
      ref={contentRef}
      role="listbox"
      aria-activedescendant={selectedValue || ""}
      aria-label={ariaLabel}
      tabIndex={-1}>
      {options.map(option => (
        <li
          key={option.value}
          className={classNames(
            "w-full flex items-center justify-center py-1 border-solid border-zinc-300 border-b",
            {
              "hover:bg-zinc-100/70 cursor-pointer": !option.disabled,
              "bg-zinc-100/70 text-zinc-500": option.disabled,
            }
          )}
          data-value={option.value}
          role="option"
          onClick={e => !option.disabled && handleOptionClick(e)}
          onKeyDown={e => !option.disabled && handleKeyDown(e)}
          aria-selected={selectedValue === option.value}
          aria-disabled={option.disabled}
          tabIndex={option.disabled ? -1 : 0}
          data-testid={`select-option-${option.value}`}>
          {typeof option.label === "string" ? (
            <span className="py-3 px-3 text-md text-center">
              {option.label}
            </span>
          ) : (
            option.label
          )}
        </li>
      ))}
    </ul>
  );
};

Options.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]).isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  onBlur: PropTypes.func,
  ariaLabel: PropTypes.string,
};

export default Options;
