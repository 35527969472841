import PropTypes from "prop-types";

//label used to show timeslots in the DataGrid
const TimeslotLabel = ({ timeslot, availableSeats }) => (
  <div>
    <p className="text-center">{timeslot}</p>
    <p className="text-center font-light">
      {availableSeats === 0 ? "Filled" : `${availableSeats} available`}
    </p>
  </div>
);

TimeslotLabel.propTypes = {
  timeslot: PropTypes.string.isRequired,
  availableSeats: PropTypes.number.isRequired,
};

export default TimeslotLabel;
