import {
  Link,
  useFetcher,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import {
  Button,
  DataGrid,
  Legend,
  LegendItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TimeslotLabel,
} from "components";
import {
  missingDataForm,
  studentDataValidation,
} from "utils/studentDataProcessing";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { rightEnd } from "constants/enums/placement";
import LegendIcon from "assets/icons/info.svg";
import { useStudentsStore } from "store/students";
import { generateSeatOptions } from "utils/schools";
import { VALIDATION_TYPE } from "constants/enums/validation";

export default function StudentInfoCompletionView() {
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const {
    school,
    studentColumns,
    students: routeStudents,
  } = useRouteLoaderData("students");

  const { students, setStudents, replaceStaleStudents } = useStudentsStore();

  const EXCLUDED_KEYS = ["start-date", "end-date"];

  const [legendOpen, setLegendOpen] = useState(false);
  const [errors, setErrors] = useState({ errorCells: [], errorMessages: [] });
  const formRef = useRef();
  const formDataRef = useRef({});

  const isDataComplete = useMemo(
    () =>
      !missingDataForm(formDataRef.current) &&
      fetcher.state === "idle" &&
      !errors.errorCells.length,
    [formDataRef.current, fetcher.state, errors.errorCells]
  );

  //TODO: remove this useEffect and use line 54 on SAYG-phase-three
  // if (routeStudents.length && !students.length) setStudents(routeStudents);
  useEffect(() => {
    setStudents(routeStudents);
  }, [routeStudents, setStudents]);

  const selectedStudents = useMemo(
    () => students.filter(student => student.selected),
    [students]
  );

  useEffect(() => {
    if (!fetcher.data) return;

    const { success, redirect, updatedStudents, errorCells, errorMessages } =
      fetcher.data;
    if (redirect) navigate(redirect);

    if (success) {
      const updatedStudentUUIDs = new Set(
        updatedStudents?.map(s => s.uuid) || []
      );

      setErrors(prev => ({
        errorCells: [
          ...prev.errorCells.filter(
            cell => !updatedStudentUUIDs.has(cell.split("_")[1])
          ),
          ...(errorCells ?? []),
        ],
        errorMessages: [
          ...prev.errorMessages.filter(msg => {
            const studentUUID = msg.cellId?.split("_")[1];
            return studentUUID && !updatedStudentUUIDs.has(studentUUID);
          }),
          ...(errorMessages ?? []),
        ],
      }));

      replaceStaleStudents(fetcher.data.updatedStudents);
    }
  }, [fetcher.data, navigate, replaceStaleStudents]);

  // Set formData once page has loaded
  useEffect(() => {
    if (selectedStudents.length > 0) {
      const form = formRef.current;
      const fd = new FormData(form);
      const dataObject = Object.fromEntries(fd.entries());

      const validationResults = studentDataValidation(dataObject, {
        [VALIDATION_TYPE.SCHOOL_SEAT]: school?.school_seats,
      });

      setErrors(validationResults);
      formDataRef.current = dataObject;
    }
  }, []);

  //assign available seats to each timeslot, we can move this back to the route after pagination
  const seatOptions = generateSeatOptions(school, students);
  studentColumns.find(column => column.key === "school-seat-id").options =
    seatOptions.map(seat => ({
      label: (
        <TimeslotLabel
          timeslot={seat.timeslot}
          availableSeats={seat.availableSeats}
        />
      ),
      readableLabel: seat.timeslot,
      value: seat.value,
      disabled: seat.disabled,
    }));

  const handleSave = useCallback(
    async e => {
      const updatedStudentFormData = {};
      const keySplit = e.target.name.split("_");

      for (const field in formDataRef.current) {
        const fieldStudentUuid = field.split("_")[1];

        // Checking student UUIDs to ensure the correct student is updated
        if (fieldStudentUuid === keySplit[1]) {
          updatedStudentFormData[field] = formDataRef.current[field];
        }
      }

      //update the edited field
      updatedStudentFormData[e.target.name] = e.target.value;
      formDataRef.current = {
        ...formDataRef.current,
        ...updatedStudentFormData,
      };

      fetcher.submit(
        { ...updatedStudentFormData, "save-students": "true" },
        {
          method: "post",
        }
      );
    },
    [fetcher, students]
  );

  return (
    <div className="mt-16 mx-24">
      <h1 className="text-ignite-purple-dark text-2xl font-semibold">
        Complete Student Information
        <Popover
          onOpenChange={setLegendOpen}
          withClick={false}
          withHover={true}
          open={legendOpen}
          placement={rightEnd}>
          <PopoverTrigger>
            <button
              aria-label="Open legend"
              data-testid="open-legend"
              className="group ml-2">
              <LegendIcon className="[&>*]:group-hover:fill-ignite-purple [&>*]:group-focus:fill-ignite-purple" />
            </button>
          </PopoverTrigger>
          <PopoverContent
            hideCloseButton
            hideArrow>
            <Legend
              title="Legend"
              closeLegend={() => setLegendOpen(false)}
              data-testid="legend">
              <LegendItem
                legendStyles="required-cell-legend"
                title="Pink - "
                subtitle="Required fields"
              />
              <LegendItem
                legendStyles="bg-white"
                title="White - "
                subtitle="No action required"
              />
              <LegendItem
                legendStyles="bg-ignite-grey-lightest"
                title="Gray - "
                subtitle="Not editable"
                isNextItemMultiLine={true}
              />
              <LegendItem
                legendStyles="cell-error-legend"
                title="Red Stripe - "
                subtitle="Needs to be updated or is missing information"
              />
            </Legend>
          </PopoverContent>
        </Popover>
      </h1>
      <p className="mt-2 text-ignite-grey-dark leading-loose w-[770px]">
        Please ensure that all highlighted cells are updated. To deselect
        students prior to enrollment, go to{" "}
        <Link
          className="text-ignite-purple underline"
          to={`/schools/${school.uuid}/student-selection`}>
          Select Students
        </Link>{" "}
        and uncheck them.
      </p>
      <div className="w-full my-12">
        <fetcher.Form
          method="post"
          id="student-info-completion-form"
          ref={formRef}>
          <DataGrid
            columns={studentColumns}
            keys={studentColumns
              .filter(col => !EXCLUDED_KEYS.includes(col.key))
              .map(col => col.key)}
            rows={selectedStudents}
            height="700px"
            width="100%"
            onEdit={handleSave}
            errors={errors.errorCells}
            errorMessages={errors.errorMessages}
          />
        </fetcher.Form>
      </div>
      {selectedStudents.length === 0 && (
        <p className="text-center pb-8">
          All selected students have been submitted.
        </p>
      )}
      <div className="flex my-12 justify-end gap-7 pb-20">
        <p className="ml-40 text-ignite-grey-dark text-sm text-right w-[430px]">
          The information you enter may be overwritten if data becomes available
          from your Student Information System (SIS).
        </p>
        {selectedStudents.length > 0 ? (
          <Button
            formId="student-info-completion-form"
            className="py-3 px-8 font-medium"
            design={isDataComplete ? "primary" : "disabled"}
            type="submit"
            data-testid="page-submit"
            disabled={!isDataComplete}>
            Submit
          </Button>
        ) : (
          <Link
            className="ignite-button-primary py-3 px-8 font-medium"
            to={`/schools/${school.uuid}/student-info-review`}>
            Next
          </Link>
        )}
      </div>
    </div>
  );
}
