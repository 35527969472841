import { VALIDATION_TYPE } from "constants/enums/validation";
import { studentColumns } from "constants/metadata/studentColumns";
import {
  validateEmail,
  validateSEPAccommodations,
  validateSEP,
  validateSchoolSeat,
} from "./validation";
import { formatDate } from "./format";
import { ENROLLMENT_STATUS } from "constants/enums/enrollment";

export const nonEditableColumns = studentColumns
  .filter(column => !column.editable)
  .map(column => column.key);

/**
 * Functions for decorating raw student data into cells for the DataGrid
 * Used in the loaders for the fetching of student data
 */

export const studentDataDecoration = students => {
  students.forEach(student => {
    decorateOneStudent(student);
  });
  return students;
};

export const decorateOneStudent = student => {
  const {
    first_name,
    last_name,
    email,
    date_of_birth,
    race,
    hispanic_or_latino_descent,
    language_spoken_at_home,
    english_language_learner,
    lunch_program,
    school_seat_id,
    special_education_program,
    special_education_program_accommodations,
    student_number,
    grade_level,
    teacher_first_name,
    teacher_last_name,
    teacher_email,
    start_date,
    end_date,
  } = student;

  // Cells should match column headers and keys maintained in constants/studentColumns.js
  student.cells = [
    {
      value: buildFullName(first_name, last_name),
      error: false,
      errorMessage: undefined,
      key: "name",
    },
    {
      value: grade_level,
      error: false,
      errorMessage: undefined,
      key: "grade-level",
    },
    {
      value: student_number?.toString(),
      error: false,
      errorMessage: undefined,
      key: "student-number",
    },
    {
      value: email || "",
      error: false,
      errorMessage: undefined,
      key: "email",
    },
    {
      value: school_seat_id?.toString(),
      error: false,
      errorMessage: undefined,
      key: "school-seat-id",
    },
    {
      value: buildFullName(teacher_first_name, teacher_last_name),
      error: false,
      errorMessage: undefined,
      key: "teacher-name",
    },
    {
      value: teacher_email || "",
      error: false,
      errorMessage: undefined,
      key: "teacher-email",
    },
    {
      value: formatDate(date_of_birth),
      error: false,
      errorMessage: undefined,
      key: "date-of-birth",
    },
    {
      value: hispanic_or_latino_descent?.toString(),
      error: false,
      errorMessage: undefined,
      key: "hispanic-or-latino-descent",
    },
    { value: race, error: false, errorMessage: undefined, key: "race" },
    {
      value: language_spoken_at_home?.toLowerCase(),
      error: false,
      errorMessage: undefined,
      key: "language-spoken-at-home",
    },
    {
      value: english_language_learner?.toString(),
      error: false,
      errorMessage: undefined,
      key: "english-language-learner",
    },
    {
      value: special_education_program,
      error: false,
      errorMessage: undefined,
      key: "special-education-program",
    },
    {
      values: JSON.parse(special_education_program_accommodations),
      error: false,
      errorMessage: undefined,
      key: "special-education-program-accommodations",
    },
    {
      value: lunch_program,
      error: false,
      errorMessage: undefined,
      key: "lunch-program",
    },
    {
      value: formatDate(start_date, "N/A"),
      error: false,
      errorMessage: undefined,
      key: "start-date",
    },
    {
      value: formatDate(end_date, "N/A"),
      error: false,
      errorMessage: undefined,
      key: "end-date",
    },
  ];

  student.cells.forEach(cell => {
    const colDependencyName = studentColumns.find(
      column => column.key === cell.key
    ).dependency;
    if (colDependencyName) {
      const colDependency = studentColumns.find(
        column => column.key === colDependencyName
      );
      const cellDependency = student.cells.find(
        cell => cell.key === colDependencyName
      );
      cell.editable = cellDependency.value !== colDependency.noneLabel;
    }
  });

  //calculates if the student should be highlighted as a newly submitted student
  student.new_row = student.enrollment_status === ENROLLMENT_STATUS.SUBMITTED;
  return student;
};

export const buildFullName = (first_name, last_name) => {
  return last_name && first_name
    ? `${last_name}, ${first_name}`
    : first_name
      ? first_name
      : last_name
        ? last_name
        : null;
};

/**
 * Functions for de-decorating transformed student data into raw data for the spark endpoint
 * Used in the actions for updating student data
 */
export const studentDataDeDecoration = async (formObject, errorCells) => {
  let students = [];
  for (let key in formObject) {
    students = deDecorateOneField(students, formObject, key, errorCells);
  }
  return students;
};

export const deDecorateOneField = (students, formObject, key) => {
  // Split the key to get the uuid and student_program_year_uuid
  const keySplit = key.split("_");

  if (
    // If the key does not have the correct format, don't add the column to the
    // student's fields to be updated
    // This will exclude named inputs in the form such as "search" and "num-open-seats"
    keySplit.length !== 3 ||
    // If the column is non-editable, don't add the column to the
    // student's fields to be updated
    nonEditableColumns.includes(keySplit[0])
  ) {
    return students;
  }

  const uuid = parseStrings("uuid", keySplit[1]);
  const student_program_year_uuid = parseStrings(
    "student_program_year_uuid",
    keySplit[2]
  );

  // Convert the first part of key to the database format by replacing
  // dashes with underscores
  const fieldName = keySplit[0].replace(/-/g, "_");

  // Convert the field value to the type that is expected by the database
  const value = parseStrings(fieldName, formObject[key]);

  // Find the student in the students array
  let studentIndex = students.findIndex(student => student.uuid === uuid);

  // If the student is not in the students array, add the student to the array
  if (studentIndex == -1) {
    students.push({
      uuid: uuid,
      student_program_year_uuid: student_program_year_uuid,
      [fieldName]: value,
    });
    studentIndex = students.length - 1;
    // If the student is in the students array, add the student's field
  } else {
    students[studentIndex][fieldName] = value;
  }

  if (students[studentIndex]["special_education_program"] === "sep_none") {
    students[studentIndex]["special_education_program_accommodations"] = "[]";
  }
  return students;
};

export const parseStrings = (key, value) => {
  if (value === "true") {
    value = true;
  } else if (value === "false") {
    value = false;
  }

  if (key === "special_education_program_accommodations") {
    if (!["[]", ""].includes(value)) {
      value =
        "[" +
        value
          .split(",")
          .map(val => `"${val.trim()}"`)
          .join(", ") +
        "]";
    } else {
      value = "[]";
    }
  }

  if (["school_seat_id"].includes(key)) {
    value = parseInt(value);
  }

  return value;
};

export const missingDataForm = dataObject => {
  if (!dataObject) return true;

  let isDataMissing = false;

  // keep track of valid keys to determine if data is present
  let validKeyCount = 0;

  // Loop through all the form entries
  for (const key in dataObject) {
    // Split the key to get the uuid and student_program_year_uuid
    const keySplit = key.split("_");

    if (
      // If the key does not have the correct format, don't add the column to the
      // student's fields to be updated
      // This will exclude named inputs in the form such as "search" and "num-open-seats"
      keySplit.length !== 3
    ) {
      continue;
    }

    validKeyCount++;
    // Find the metadata about the current cell
    const column = studentColumns.find(column => column.key === keySplit[0]);
    // If there is no dependency defined, the dependency check is not relevant
    // so it is set to true and the cell needs to be filled
    let dependencyCheck = true;

    // If there is a dependency defined, we need to check if the dependent column
    // has a "none" value. If it does, the cell does not need to be filled
    if (column?.dependency) {
      // Find the metadata about the column that this column depends on
      const dependencyColumn = studentColumns.find(
        col => col.key === column.dependency
      );
      // Find the form entry for the column that this column depends on
      const dependencyForm =
        dataObject[`${column.dependency}_${keySplit[1]}_${keySplit[2]}`];
      // If the cell value is not the noneLabel, then the cell needs to be filled
      dependencyCheck = dependencyForm !== dependencyColumn.noneLabel;
    }

    if (
      // If there is no value, if the cell is editable, and if the
      // dependency check is true, then the data is missing
      (!dataObject[key] || dataObject[key] === "[]") &&
      !nonEditableColumns.includes(keySplit[0]) &&
      dependencyCheck
    ) {
      isDataMissing = true;
      break;
    }
  }
  return !validKeyCount || isDataMissing;
};

export const studentDataValidation = (
  formObject,
  maxValuesForAggValidations
) => {
  let aggCounters = {};
  const { errorCells, errorMessages } = Object.keys(formObject).reduce(
    (acc, key) => {
      const keySplit = key.split("_");
      const invalidKey =
        // If the key does not have the correct format
        // This will exclude named inputs in the form such as "search" and "num-open-seats"
        keySplit.length !== 3 ||
        // If the column is non-editable
        nonEditableColumns.includes(keySplit[0]);

      if (invalidKey) return acc;

      const column = studentColumns.find(column => column.key === keySplit[0]);
      const validationType = column?.validationType;

      if (!validationType) return acc;

      if (validationType) {
        if (!aggCounters[validationType]) {
          aggCounters[validationType] = {};
        }
        const { errorCell, errorMessage } = validateCell(
          validationType,
          key,
          formObject[key],
          formObject,
          aggCounters,
          maxValuesForAggValidations
        );

        if (!errorMessage || { message: errorMessage } in acc.errorMessages)
          return acc;

        return {
          errorCells: errorCell
            ? [...acc.errorCells, errorCell]
            : acc.errorCells,
          errorMessages: [
            ...acc.errorMessages,
            errorCell
              ? { message: errorMessage, cellId: errorCell }
              : { message: errorMessage },
          ],
        };
      }
    },
    { errorCells: [], errorMessages: [] }
  );

  return {
    error: errorMessages.length > 0,
    errorCells,
    errorMessages,
  };
};

export const validateCell = (
  validationType,
  cellKey,
  cellValue,
  formObject,
  aggCounters,
  maxValuesForAggValidations
) => {
  let errorCell;
  let errorMessage;

  if (!validationType) {
    return { errorCell, errorMessage };
  }

  switch (validationType) {
    case VALIDATION_TYPE.EMAIL:
      if (!validateEmail(cellValue)) {
        errorCell = cellKey;
        errorMessage = `Please enter a valid email address. Ensure it includes "@" and a domain.`;
      }
      break;
    case VALIDATION_TYPE.SEP_ACCOMMODATIONS:
      if (!validateSEPAccommodations(cellKey, formObject)) {
        errorCell = cellKey;
        errorMessage =
          "Special Education Accommodation is required for students in a Special Education Program.";
      }
      break;
    case VALIDATION_TYPE.SEP:
      if (!validateSEP(cellKey, formObject)) {
        errorCell = cellKey.replace(
          "special-education-program",
          "special-education-program-accommodations"
        );
        errorMessage =
          "Please select a Special Education Program Accommodation for the Special Education Program selected.";
      }
      break;
    case VALIDATION_TYPE.SCHOOL_SEAT: {
      const schoolSeats =
        maxValuesForAggValidations[VALIDATION_TYPE.SCHOOL_SEAT];
      const schoolSeat = schoolSeats.filter(
        seat => seat.id.toString() === cellValue.toString()
      )[0];
      if (
        !validateSchoolSeat(
          aggCounters[VALIDATION_TYPE.SCHOOL_SEAT],
          cellValue,
          schoolSeat
        )
      ) {
        errorMessage = schoolSeat
          ? `Max seat limit is ${schoolSeat.num_seats} for this tutoring time slot ${schoolSeat.formatted_time_range}.`
          : "A tutoring time slot must be selected.";
        if (!schoolSeat) {
          errorCell = cellKey;
        }
      }
      break;
    }
    default:
      errorMessage = "Error validating data, please contact Ignite support.";
  }

  return { errorCell, errorMessage };
};

export const applyErrors = (students, errorCells, errorMessages) => {
  if (!students || !students.length) return students;
  students.forEach(student => {
    student.cells.forEach(cell => {
      cell.error = false;
      cell.errorMessage = undefined;
    });
  });
  if (!errorCells || !errorCells.length) return students;
  errorCells.forEach((errorCell, index) => {
    // eslint-disable-next-line no-unused-vars
    const [columnKey, uuid, __] = errorCell.split("_");
    const student = students.find(student => student.uuid === uuid);
    const cell = student.cells.find(cell => cell.key === columnKey);
    cell.error = true;
    cell.errorMessage = errorMessages[index];
  });
  return students;
};
