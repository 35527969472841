import PropTypes from "prop-types";
import { DataGridProvider } from "./DataGridProvider";
import DataGridTable from "./DataGridTable";
import ErrorSummary from "./ErrorSummary";
import { dataGridColumns, dataGridRows } from "./proptypes";
/**
 * Reusable and styled data grid.
 *
 * NOTE: DataGrid currently assumes the first column will always be Last Name/First Name
 */

function DataGrid({
  columns,
  rows,
  keys = [],
  onSelect = () => {},
  onEdit = () => {},
  multiSelect = false,
  disableSelect = false,
  allowEdits = true,
  className,
  height,
  width,
  errors,
  errorMessages = [],
}) {
  return (
    <DataGridProvider
      columns={columns}
      rows={rows}
      keys={keys}
      onSelect={onSelect}
      multiSelect={multiSelect}
      disableSelect={disableSelect}
      allowEdits={allowEdits}
      errors={errors}
      errorMessages={errorMessages}>
      <DataGridTable
        className={className}
        height={height}
        width={width}
        onEdit={onEdit}
      />
    </DataGridProvider>
  );
}

DataGrid.propTypes = {
  columns: dataGridColumns,
  rows: dataGridRows,
  keys: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  multiSelect: PropTypes.bool,
  disableSelect: PropTypes.bool,
  allowEdits: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      errorId: PropTypes.string,
    })
  ),
};

DataGrid.ErrorSummary = ErrorSummary;

export default DataGrid;
