import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useStudentsStore = create(
  devtools(set => ({
    students: [],
    setStudents: newStudents =>
      set({ students: newStudents }, false, "setStudents"),
    replaceStaleStudents: newStudents => {
      set(
        state => {
          const freshMap = new Map(
            newStudents.map(student => [student.id, student])
          );
          return {
            students: state.students.map(
              student => freshMap.get(student.id) || student
            ),
          };
        },
        false,
        "replaceStaleStudents"
      );
    },
  }))
);
