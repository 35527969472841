import { studentDataDecoration } from "utils/studentDataProcessing";
import SchoolService from "services/School";
import AuthService from "services/Auth";
import { redirect } from "react-router-dom";
import StudentSelectionRoute from "./StudentSelection";
import StudentInfoCompletionRoute from "./StudentInfoCompletion";
import StudentsReviewRoute from "./StudentInfoReview";
import { studentColumns } from "constants/metadata/studentColumns";
import StudentsRedirectRoute from "./StudentsRedirect";

export default {
  id: "students",
  path: "/schools/:schoolId",
  children: [
    StudentInfoCompletionRoute,
    StudentSelectionRoute,
    StudentsReviewRoute,
    StudentsRedirectRoute,
  ],
  loader: async ({ params }) => {
    const SPHINX_GATE_API_URL = import.meta.env.VITE_SPHINX_GATE_API_URL;
    //check for school id in path
    const { schoolId } = params;
    //redirect if schoolId is missing
    if (!schoolId) return redirect(`/`);
    await AuthService.auth();
    if (AuthService.isAuthenticated) {
      SchoolService.api.token = AuthService.api.token;

      const { school, status } = await SchoolService.school(schoolId);

      //redirect if schoolId is invalid
      if (status == 404 || !school) return redirect(`/`);

      const { students } = await SchoolService.students(schoolId);
      await studentDataDecoration(students.students);

      return {
        numOpenSeats: school.num_open_seats,
        school: school,
        students: students.students,
        studentColumns,
      };
    }
    return redirect(`${SPHINX_GATE_API_URL}/login?source=schools`);
  },
  //TODO: remove in SAYG-phase-three
  shouldRevalidate: () => true,
};
