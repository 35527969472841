import { useContext, useEffect, useRef, useState } from "react";
import { DataGridContext } from "./DataGridProvider";
import PropTypes from "prop-types";
import classnames from "classnames";
import DataGridSearch from "./DataGridSearch";
import { DataGridRow, DataGridHeaderRow } from "./DataGridRows";

function DataGridTable({ className, height = "100%", width = "100%", onEdit }) {
  const { currRows, currColumns, multiSelect } = useContext(DataGridContext);
  const scrollBody = useRef(null);
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => setScrolling(scrollBody.current.scrollTop > 0);

  useEffect(() => {
    if (scrollBody.current)
      scrollBody.current.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollBody.current)
        scrollBody.current.removeEventListener("scroll", handleScroll);
    };
  }, [scrollBody]);

  return (
    <>
      <DataGridSearch />
      <div
        className="scrollbar-thin scrollbar-thumb-[#87878799] scrollbar-track-transparent overflow-scroll border-[.75px] border-ignite-grey-border rounded-bl-[5px] rounded-br-[5px] rounded-tr-[5px] box-shadow min-w-[560px]"
        style={{
          maxHeight: height,
          width,
          scrollbarGutter: "stable both-edges",
        }}
        ref={scrollBody}>
        <table
          tabIndex="-1"
          role="grid"
          aria-colcount={currColumns.length + 1}
          aria-rowcount={currRows.length + 1}
          aria-multiselectable={multiSelect}
          className={classnames(
            "border-separate border-spacing-0 h-px",
            className
          )}>
          <DataGridHeaderRow scrolling={scrolling} />
          <tbody>
            {currRows
              .filter(row => !row.filterOut)
              .map((row, i) => (
                <DataGridRow
                  key={row.uuid}
                  cells={row.cells}
                  uuids={`${row.uuid}_${row.student_program_year_uuid}`}
                  selected={row.selected}
                  rowIndex={i + 2}
                  onEdit={onEdit}
                  newRow={row.new_row}
                />
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

DataGridTable.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  onEdit: PropTypes.func,
};

export default DataGridTable;
