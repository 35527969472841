import PropTypes from "prop-types";
import classnames from "classnames";
import DownArrowIcon from "assets/icons/arrow_down.svg";
import {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { Popover, PopoverContent, PopoverTrigger } from "components/Popover";
import { twMerge } from "tailwind-merge";
import SelectedOptions from "../DataGridMultiSelect/SelectedOptions";
import Options from "./Options";

/**
 * Styled select for the DataGrid.
 *
 * @param {boolean} [error = false] - boolean to indicate error state
 * @param {boolean} [disabled = false] - boolean to indicate disabled state
 * @param {string} value - value to display in the select
 * @param {string} name - name of the select
 * @param {string} id - id of the select
 * @param {string} className - additional classes to add to the component
 * @param {array} options - array of objects with label and value for the options
 * @param {string} columnHeader - column header for the field
 * @param {boolean} required - boolean to indicate if the field is required
 * @param {func} onChange - function to run on blur event
 */
const DataGridSelect = ({
  error = false,
  disabled = false,
  value = "",
  name = "",
  id = "",
  className,
  options,
  columnHeader,
  required = false,
  onChange,
  label = "Select",
  ...args
}) => {
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [contentWidth, setContentWidth] = useState(null);

  const triggerRef = useRef();
  const scrollableParentRef = useRef();
  const lastExecutionRef = useRef(0); // For throttling checkVisibility()
  const firstColWidth = useRef(357);

  // Finds the nearest scrollable ancestor to be used in visibility logic.
  const getScrollableParent = useCallback(node => {
    while (node && node !== document.body) {
      const { overflowY, overflowX } = window.getComputedStyle(node);
      if (
        ["auto", "scroll"].includes(overflowY) ||
        ["auto", "scroll"].includes(overflowX)
      ) {
        return node;
      }
      node = node.parentNode;
    }
    return null;
  }, []);

  // Checks if the left side of the cell is fully visible inside the scrollable container.
  // Left side was chosen because that is where the popover appears.
  const checkVisibility = useCallback(() => {
    const now = Date.now();

    // Maximum of 50 calculations per second (once every 20ms). Seems fast at first, but is still significantly throttled compared to every render.
    // Without throttling, this runs roughly once every 6-7ms on my machine.
    if (now - lastExecutionRef.current >= 20) {
      lastExecutionRef.current = now;

      if (triggerRef.current && scrollableParentRef.current) {
        const rect = triggerRef.current.getBoundingClientRect();
        const parentRect = scrollableParentRef.current.getBoundingClientRect();
        setIsVisible(
          rect.top >= parentRect.top &&
            rect.bottom <= parentRect.bottom &&
            rect.left <= parentRect.right - rect.width * 0.6 &&
            rect.left >=
              parentRect.left +
                (firstColWidth.current - triggerRef.current.offsetWidth) +
                rect.width * 0.6 //calculate the left side
        );
      }
    }
  }, []);

  useEffect(() => {
    if (open && triggerRef.current) {
      const width = triggerRef.current.getBoundingClientRect().width;
      setContentWidth(width);

      window.addEventListener("resize", checkVisibility);

      const scrollableParent = getScrollableParent(triggerRef.current);
      scrollableParentRef.current = scrollableParent;

      if (scrollableParent) {
        scrollableParent.addEventListener("scroll", checkVisibility);
      }
      checkVisibility();

      return () => {
        window.removeEventListener("resize", checkVisibility);

        if (scrollableParent) {
          scrollableParent.removeEventListener("scroll", checkVisibility);
        }
      };
    }
  }, [open]);

  useLayoutEffect(() => {
    firstColWidth.current = document.getElementById("col-1")?.offsetWidth;
  }, []);

  const handleSelectOption = eventValue => {
    setOpen(false);
    onChange({
      target: {
        name,
        value: eventValue,
      },
    });
  };

  const handleOpen = bool => {
    setOpen(bool);
    if (!bool) triggerRef.current?.focus({ preventScroll: true });
  };

  return (
    <Popover
      onOpenChange={handleOpen}
      open={open && isVisible}
      boundaryRef={scrollableParentRef.current}
      placement="bottom"
      offset={{ mainAxis: 0 }}
      useFlip={false}
      useShift={false}
      toggle>
      <PopoverTrigger>
        <button
          className={twMerge(
            classnames(
              "group flex items-center whitespace-nowrap h-full w-full justify-center",
              "outline-2 outline-offset-[-1px] focus:rounded focus:outline focus:outline-ignite-pink",
              {
                "shadow-inner rounded outline outline-ignite-pink": open,
              }
            )
          )}
          data-testid={name}
          aria-label={`Select ${columnHeader}`}
          // NOTE: we disable this error because floating-ui adds aria attributes FOR us
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          aria-haspopup="listbox"
          aria-expanded={open}
          aria-required={required}
          aria-controls={`${id}-select-content`}
          id={id}
          ref={triggerRef}
          disabled={disabled}
          type="button"
          {...args}>
          {value ? (
            <SelectedOptions
              selectedValues={[value]}
              options={options}
              name={name}
              error={error}
            />
          ) : (
            <>
              <label
                htmlFor={id}
                className={classnames("pr-9", {
                  "cursor-pointer": !disabled,
                  "text-error-red": error,
                })}>
                {label}
              </label>
              <input
                aria-hidden={true}
                className="invisible absolute bottom-0"
                name={name}
                value={""}
                readOnly
              />
            </>
          )}
          {/* hidden input to handle invalid state */}
          <input
            aria-invalid={error}
            aria-hidden={true}
            tabIndex={-1}
            className="invisible absolute bottom-0"
          />
          <DownArrowIcon
            className={classnames(
              "absolute pointer-events-none top-[calc(50%-.75em)] right-4 rounded-full box-content",
              {
                "cursor-pointer group-focus:bg-ignite-pink group-hover:bg-ignite-pink group-focus:fill-white group-hover:fill-white":
                  !disabled,
                "-rotate-180": open,
              }
            )}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent
        hideArrow
        hideCloseButton
        className="rounded-lg mt-2 border border-solid border-zinc-300">
        <div
          data-testid={`${id}-select-content`}
          className={twMerge(
            "flex flex-col max-h-[300px] overflow-hidden",
            className
          )}
          style={{ width: contentWidth ? `${contentWidth}px` : "auto" }}>
          <Options
            options={options}
            onChange={handleSelectOption}
            selectedValue={value}
            name={name}
            ariaLabel={`Select ${columnHeader}`}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

DataGridSelect.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]).isRequired,
      isDisabled: PropTypes.bool,
    })
  ).isRequired,
  columnHeader: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default DataGridSelect;
