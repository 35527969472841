import { useRouteError } from "react-router-dom";
import ErrorMessage from "components/ErrorMessage";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

export default function ErrorBoundary() {
  const error = useRouteError();

  useEffect(() => {
    Sentry.captureException(error);
    console.error("Error sent to Sentry:", error);
  }, [error]);

  return (
    <ErrorMessage
      status={error.status}
      message={error.message ? error.message : "An error occurred"}
    />
  );
}
