import { SORT_DIRECTION, SORT_TYPE } from "constants/enums/sort";
import { DATA_TYPE } from "constants/metadata/data_type";
import PropTypes from "prop-types";

export const dataGridColumn = PropTypes.shape({
  colName: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  dataType: PropTypes.oneOf([
    DATA_TYPE.SELECT,
    DATA_TYPE.STRING,
    DATA_TYPE.DATE,
    DATA_TYPE.MULTISELECT,
  ]).isRequired,
  sortType: PropTypes.oneOf([
    SORT_TYPE.ALPHANUMERIC,
    SORT_TYPE.DATE,
    SORT_TYPE.GRADE,
    SORT_TYPE.DISABLED,
  ]).isRequired,
  activeSortDirection: PropTypes.oneOf([
    SORT_DIRECTION.ASCENDING,
    SORT_DIRECTION.DESCENDING,
    SORT_DIRECTION.NONE,
  ]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]),
      disabled: PropTypes.bool,
    })
  ),
});

export const dataGridColumns = PropTypes.arrayOf(dataGridColumn).isRequired;

export const dataGridCell = PropTypes.shape({
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool.isRequired,
});

export const dataGridCells = PropTypes.arrayOf(dataGridCell).isRequired;

export const dataGridRow = PropTypes.shape({
  selected: PropTypes.bool,
  uuid: PropTypes.string.isRequired,
  cells: dataGridCells,
});

export const dataGridRows = PropTypes.arrayOf(dataGridRow).isRequired;
