import PropTypes from "prop-types";
import { useRef, useCallback, useEffect, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "components/Popover";
import ErrorItem from "../ErrorSummary/ErrorItem";

const ErrorSummaryInline = ({ cell, children }) => {
  const HEADER_LINE_HEIGHT = 56;

  const contentRef = useRef();
  const scrollableParentRef = useRef();
  const lastExecutionRef = useRef(0); // For throttling checkVisibility()

  const [isVisible, setIsVisible] = useState(true);

  // Finds the nearest scrollable ancestor to be used in visibility logic.
  const getScrollableParent = useCallback(node => {
    while (node && node !== document.body) {
      const { overflowY, overflowX } = window.getComputedStyle(node);
      if (
        ["auto", "scroll"].includes(overflowY) ||
        ["auto", "scroll"].includes(overflowX)
      ) {
        return node;
      }
      node = node.parentNode;
    }
    return null;
  }, []);

  // Checks if the left side of the cell is fully visible inside the scrollable container.
  // Left side was chosen because that is where the popover appears.
  const checkVisibility = useCallback(() => {
    const now = Date.now();

    // Maximum of 50 calculations per second (once every 20ms). Seems fast at first, but is still significantly throttled compared to every render.
    // Without throttling, this runs roughly once every 6-7ms on my machine.
    if (now - lastExecutionRef.current >= 20) {
      lastExecutionRef.current = now;

      if (contentRef.current && scrollableParentRef.current) {
        const rect = contentRef.current.getBoundingClientRect();
        const parentRect = scrollableParentRef.current.getBoundingClientRect();
        setIsVisible(
          rect.top >= parentRect.top + HEADER_LINE_HEIGHT &&
            rect.bottom <= parentRect.bottom &&
            rect.left <= parentRect.right &&
            rect.left >= parentRect.left
        );
      }
    }
  }, []);

  useEffect(() => {
    if (cell.errorMessage && contentRef.current) {
      window.addEventListener("resize", checkVisibility);

      const scrollableParent = getScrollableParent(contentRef.current);
      scrollableParentRef.current = scrollableParent;

      if (scrollableParent) {
        scrollableParent.addEventListener("scroll", checkVisibility);
      }
      checkVisibility();

      return () => {
        window.removeEventListener("resize", checkVisibility);

        if (scrollableParent) {
          scrollableParent.removeEventListener("scroll", checkVisibility);
        }
      };
    }
  }, [cell.errorMessage]);

  if (!cell.errorMessage || !cell.error) {
    return children;
  }

  return (
    <Popover
      open={cell.error && isVisible}
      boundaryRef={scrollableParentRef.current}
      useShift={false}
      placement="left"
      offset={{ mainAxis: 8 }}>
      <PopoverTrigger>
        <div
          className="flex h-full w-full items-center justify-center"
          ref={contentRef}>
          {children}
        </div>
      </PopoverTrigger>
      <PopoverContent
        hideCloseButton
        className="max-w-[390px] text-wrap rounded text-sm px-4 py-2 drop-shadow-lg"
        data-testid="data-grid-inline-error-popover-content">
        <ErrorItem
          error={cell.errorMessage}
          disableLink
        />
      </PopoverContent>
    </Popover>
  );
};

ErrorSummaryInline.propTypes = {
  cell: PropTypes.shape({
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.shape({
      message: PropTypes.string.isRequired,
      errorId: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default ErrorSummaryInline;
