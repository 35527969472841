import PropTypes from "prop-types";
import classnames from "classnames";
import Checkbox from "components/Checkbox";
import Tooltip from "components/Tooltip";
import { dataGridCell } from "components/DataGrid/proptypes";
import { useState } from "react";

export default function DataGridRowHeaderCell({
  cell,
  rowIndex,
  totalRows,
  allowEdits,
  multiSelect,
  requiresEntry,
  hasCellErrors,
  newRow,
  selected,
  onSelect,
  disabled,
  uuids,
}) {
  const [lastName, firstName] = cell.value.split(", ");

  const firstNameTooLong = firstName && firstName.length > 12;
  const lastNameTooLong = lastName && lastName.length > 12;

  const [checkedValue, setCheckedValue] = useState(selected);

  const onCheckCombined = async event => {
    if (event.target.checked) {
      setCheckedValue(true);
    } else {
      setCheckedValue(false);
    }
    onSelect(event);
  };

  return (
    <th
      scope="row"
      aria-colindex="1"
      aria-rowindex={rowIndex}
      className={classnames(
        "h-14 box-content sticky left-0 z-[4] font-medium border-r-[.75px] border-ignite-grey-border max-w-[290px] box-shadow shadow-right",
        "before:content-[''] before:absolute before:top-0 before:left-0 before:h-[101%] before:w-2.5",
        newRow ? "bg-ignite-purple-highlight" : "bg-white",
        {
          "border-b-[.75px]": rowIndex - 1 !== totalRows,
          "rounded-bl-[5px]": rowIndex - 1 === totalRows,
          "before:bg-ignite-purple":
            requiresEntry && allowEdits && !hasCellErrors,
          "cell-error": hasCellErrors,
        }
      )}>
      <div className="flex justify-end items-center">
        {multiSelect && (
          <Checkbox
            checked={checkedValue}
            onCheck={onCheckCombined}
            disabled={disabled}
            ariaLabel={`Select ${lastName}, ${firstName}`}
            value={checkedValue?.toString()}
            name={`selected_${uuids}`}
            data-testid={`select-${lastName}-${firstName}`}
          />
        )}
        <div
          className={classnames(
            "text-left w-[125px] whitespace-nowrap overflow-hidden text-ellipsis",
            {
              "opacity-40": disabled,
            }
          )}>
          {lastNameTooLong ? (
            <Tooltip>
              <Tooltip.Trigger>
                <span data-testid="last-name-tooltip">{lastName}</span>
              </Tooltip.Trigger>
              <Tooltip.Content>{lastName}</Tooltip.Content>
            </Tooltip>
          ) : (
            <span>{lastName}</span>
          )}
        </div>
        <div
          className={classnames(
            "text-left w-[125px] whitespace-nowrap overflow-hidden text-ellipsis",
            {
              "opacity-40": disabled,
            }
          )}>
          {firstNameTooLong ? (
            <Tooltip>
              <Tooltip.Trigger>
                <span data-testid="first-name-tooltip">{lastName}</span>
              </Tooltip.Trigger>
              <Tooltip.Content>{firstName}</Tooltip.Content>
            </Tooltip>
          ) : (
            <span>{firstName}</span>
          )}
        </div>
        {/* empty div to space for sort button */}
        <div className="w-10" />
      </div>
    </th>
  );
}

DataGridRowHeaderCell.propTypes = {
  cell: dataGridCell,
  rowIndex: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  allowEdits: PropTypes.bool.isRequired,
  multiSelect: PropTypes.bool,
  requiresEntry: PropTypes.bool.isRequired,
  hasCellErrors: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  uuids: PropTypes.string.isRequired,
  newRow: PropTypes.bool,
};
