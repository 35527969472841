import DataGridInput from "../DataGridInput";
import PropTypes from "prop-types";
import classnames from "classnames";
import { dataGridCell, dataGridColumn } from "components/DataGrid/proptypes";
import { compareInsensitive } from "utils/compare";
import { labelLookup, cellType } from "utils/dataGrid";
import { CELL_VALUE_TYPE } from "constants/metadata/cell_value_type";
import ErrorSummaryInline from "components/DataGrid/ErrorSummaryInline";

export default function DataGridRowBodyCell({
  cell,
  cellIndex,
  rowIndex,
  column,
  totalRows,
  totalCols,
  allowEdits,
  disabled,
  uuids,
  onEdit,
}) {
  const handleChange = event => {
    onEdit && onEdit(event);
  };

  const cellValueType = cellType(allowEdits, column, cell);
  const editable =
    column.editable && (cell.editable == undefined ? true : cell.editable);

  /**
   * requiresEntry does not currently account for dependent fields because the cell will be disabled if
   * the dependency is not met. If this changes, then this check needs to take into account dependencies.
   */
  const requiresEntry =
    allowEdits &&
    editable &&
    column.required &&
    !(cell.value || cell.values?.length);

  return (
    <td
      role="gridcell"
      aria-colindex={cellIndex + 2}
      aria-rowindex={rowIndex}
      title={labelLookup(cell.value, column)}
      aria-label={cell.value}
      aria-readonly={!editable}
      className={classnames(
        "relative h-full border-ignite-grey-border",
        "before:content-[''] before:absolute before:top-0 before:left-0 before:h-[101%] before:w-2.5 before:z-[2]",
        {
          "border-b-[.75px]": rowIndex - 1 !== totalRows,
          "border-r-[.75px]": cellIndex !== totalCols,
          "px-4 whitespace-nowrap text-center overflow-hidden text-ellipsis":
            !allowEdits || !editable, // read-only cell styling
          "bg-ignite-grey-lightest": allowEdits && !editable, // read-only cell styling when allowing edits
          "bg-ignite-pink-light cell-error": cell.error, //error styling
          "opacity-40": disabled, //disabled styling
          "bg-ignite-pink-light before:bg-ignite-purple pl-2":
            requiresEntry && !cell.error, //pink + purple required field styling
        }
      )}>
      <ErrorSummaryInline cell={cell}>
        {(() => {
          switch (cellValueType) {
            case CELL_VALUE_TYPE.EDITABLE:
              return (
                <DataGridInput
                  error={cell.error}
                  disabled={disabled}
                  dataType={column.dataType}
                  options={column.options}
                  colHeader={column.colName}
                  required={column.required}
                  value={cell.value}
                  values={cell.values}
                  name={`${column.key}_${uuids}`}
                  id={`${column.key}_${uuids}`}
                  handleChange={handleChange}
                />
              );
            case CELL_VALUE_TYPE.MULTI_SELECT_READ_ONLY:
              return (
                <ul className="text-left text-pretty">
                  {cell.values?.map((value, i) => (
                    <li
                      className="pl-3 py-1 pr-10"
                      key={i}>
                      {
                        column.options.find(opt =>
                          compareInsensitive(opt.value, value)
                        )?.label
                      }
                    </li>
                  ))}
                </ul>
              );
            case CELL_VALUE_TYPE.MULTI_SELECT_DISABLED:
              return (
                <input
                  aria-hidden={true}
                  form="student-info-completion-form"
                  className="invisible absolute bottom-0"
                  name={`${column.key}_${uuids}`}
                  value={""}
                  data-testid={`${column.key}_${uuids}`}
                  readOnly
                />
              );
            case CELL_VALUE_TYPE.SELECT_READ_ONLY:
              return (
                <span>
                  {
                    column.options.find(opt =>
                      compareInsensitive(opt.value, cell.value)
                    )?.label
                  }
                </span>
              );
            default:
              return <span>{cell.value}</span>;
          }
        })()}
      </ErrorSummaryInline>
    </td>
  );
}

DataGridRowBodyCell.propTypes = {
  cell: dataGridCell,
  cellIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  column: dataGridColumn,
  totalRows: PropTypes.number.isRequired,
  totalCols: PropTypes.number.isRequired,
  allowEdits: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  uuids: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
};
