import PropTypes from "prop-types";
import classNames from "classnames";
import PencilIcon from "assets/icons/pencil-edit.svg";
import Button from "components/Button";
import { useEffect, useRef, useState } from "react";

/**
 * Component for showing editable text fields in the DataGrid.
 *
 * @param {boolean} [error = false] - boolean to indicate error state
 * @param {boolean} [disabled = false] - boolean to indicate disabled state
 * @param {string} value - value to display in the field
 * @param {string} name - name of the field
 * @param {string} id - id of the field
 * @param {string} placeholder - placeholder text for the field
 * @param {string} columnHeader - column header for the field
 * @param {func} onBlur - function to run on blur event
 * @param {string} className - additional classes to add to the component
 * @param {boolean} required - boolean to indicate if the field is required
 */
function DataGridTextInput({
  error = false,
  disabled = false,
  id = "",
  name = "",
  value = "",
  placeholder = "",
  columnHeader,
  className,
  required = false,
  onBlur,
  ...args
}) {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isActive)
      inputRef.current && inputRef.current.focus({ preventScroll: true });
  }, [isActive, inputRef]);

  return (
    <div
      className={classNames(
        "relative w-full h-full flex justify-center focus-within:ring-2 focus-within:ring-ignite-pink",
        className
      )}>
      {isActive ? (
        <input
          className={classNames(
            "w-full h-full text-center min-w-[268px] focus-visible:outline-none",
            { "text-error-red": error }
          )}
          onBlur={e => {
            setIsActive(false);
            onBlur?.(e);
          }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              e.target.blur();
            }
          }}
          onChange={e => setInputValue(e.target.value)}
          aria-invalid={error}
          aria-required={required}
          aria-label={columnHeader}
          data-testid={name}
          type="text"
          value={inputValue}
          name={name}
          id={id}
          ref={inputRef}
          {...args}
        />
      ) : (
        <div className="flex items-center justify-between w-full">
          <button
            onClick={() => setIsActive(true)}
            tabIndex={-1}
            className={classNames(
              "w-full h-fit text-center mx-7 text-nowrap outline-0",
              !inputValue && placeholder && "font-light"
            )}>
            {inputValue || placeholder}
          </button>
          <Button
            design="icon"
            className="mr-5"
            onClick={() => setIsActive(true)}
            testID={`edit-field-${name}`}
            disabled={disabled}
            ariaLabel="Edit field">
            <PencilIcon />
          </Button>
          <input
            aria-invalid={error}
            aria-hidden={true}
            className="invisible absolute bottom-0"
            tabIndex={-1}
            type="text"
            value={inputValue}
            name={name}
            data-testid={name}
            readOnly
          />
        </div>
      )}
    </div>
  );
}

export default DataGridTextInput;

DataGridTextInput.propTypes = {
  error: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  columnHeader: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
};
