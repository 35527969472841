import PropTypes from "prop-types";
import classnames from "classnames";
import { memo, useState } from "react";
import classNames from "classnames";

/**
 * Component for showing editable date values in the DataGrid.
 *
 * @param {boolean} [error = false] - boolean to indicate error state
 * @param {boolean} [disabled = false] - boolean to indicate disabled state
 * @param {string} value - value to display in the field
 * @param {string} columnHeader - the header of the column
 * @param {string} min - minimum date value
 * @param {string} max - maximum date value
 * @param {string} className - additional classes to add to the component
 * @param {boolean} required - boolean to indicate if the field is required
 * @param {func} onChange - function to run on change event
 */
function DataGridDateInput({
  error = false,
  disabled = false,
  value = "",
  columnHeader = "",
  min = "",
  max = "",
  className,
  required = false,
  onChange,
  ...args
}) {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = event => {
    setInputValue(event.target.value);
    onChange && onChange(event);
  };

  return (
    <div
      className={classnames(
        "relative w-full h-full flex content-center flex-wrap",
        className
      )}>
      <input
        className={classNames("w-full h-14 p-6 pl-12 font-medium rounded-md", {
          "focus-visible:outline-error-red text-error-red": error,
          "focus-visible:outline-ignite-pink": !disabled && !error,
        })}
        type="date"
        value={inputValue}
        onChange={handleChange}
        aria-label={columnHeader}
        aria-invalid={error}
        aria-required={required}
        disabled={disabled}
        min={min}
        max={max}
        {...args}
      />
    </div>
  );
}

export const comparator = (prevProps, nextProps) => {
  return (
    nextProps.value === prevProps.value && nextProps.error === prevProps.error
  );
};

export default memo(DataGridDateInput, comparator);

DataGridDateInput.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  columnHeader: PropTypes.string.isRequired,
  min: PropTypes.string,
  max: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};
