import { includesInsensitive, compareInsensitive } from "./compare";
import { CELL_VALUE_TYPE } from "constants/metadata/cell_value_type";
import { DATA_TYPE } from "constants/metadata/data_type";

export const rowsByKeys = (rows, keys) => {
  if (keys.length === 0) return rows; //an empty array means "all"

  return rows.map(row => ({
    ...row,
    cells: row.cells.filter(cell => keys.includes(cell.key)),
  }));
};

export const colsByKeys = (columns, keys) => {
  if (keys.length === 0) return columns; //an empty array means "all"

  return columns.filter(column => keys.includes(column.key));
};

// We can abstract out more in the future to search by any key, or multiple.
export const rowsByName = (rows, name) => {
  rows.forEach(row => {
    const nameCell = row.cells.find(cell => cell.key === "name");
    const strippedName = nameCell.value.replace(/,/g, "");

    if (
      nameCell &&
      (includesInsensitive(nameCell.value, name) ||
        includesInsensitive(strippedName, name))
    ) {
      row.filterOut = false;
    } else {
      row.filterOut = true;
    }
  });
  return rows;
};

export const labelLookup = (value, column) => {
  if (column.options) {
    const option = column.options.find(opt =>
      compareInsensitive(opt.value, value)
    );
    return option?.readableLabel || option?.label || value;
  }
  return value;
};

export const cellType = (allowEdits, column, cell) => {
  if (
    allowEdits &&
    column.editable &&
    (cell.editable == undefined || cell.editable)
  ) {
    return CELL_VALUE_TYPE.EDITABLE;
  }
  // For multiselect,
  // DataGrid edits aren't allowed so we want to display the values selected
  // Column edits aren't allowed so we want to display the values selected
  if (
    column.dataType == DATA_TYPE.MULTISELECT &&
    (!allowEdits || !column.editable) &&
    column.options
  ) {
    return CELL_VALUE_TYPE.MULTI_SELECT_READ_ONLY;
  }
  // For multiselect,
  // We've disabled this cell so we don't want to display the values selected
  if (column.dataType == DATA_TYPE.MULTISELECT && column.options)
    return CELL_VALUE_TYPE.MULTI_SELECT_DISABLED;
  if (column.options) return CELL_VALUE_TYPE.SELECT_READ_ONLY;
  return CELL_VALUE_TYPE.READ_ONLY;
};

export const getRequiresEntry = (columns, cells) => {
  const requiredFieldCols = columns.filter(col => {
    if (!col.required) return false;
    if (!col.dependency) return true;

    const dependentCol = columns.find(subCol =>
      compareInsensitive(subCol.key, col.dependency)
    );
    const dependentEntry = cells.find(cell =>
      compareInsensitive(cell.key, col.dependency)
    );

    return (
      dependentCol &&
      dependentEntry &&
      !compareInsensitive(dependentEntry.value, dependentCol.noneLabel)
    );
  });

  return requiredFieldCols.some(col => {
    const requiredField = cells.find(cell =>
      compareInsensitive(cell.key, col.key)
    );

    return !(requiredField.value || requiredField.values?.length);
  });
};
